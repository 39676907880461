import { legacy_createStore as createStore } from 'redux';

const initialState =  {
    count: 0,
    economy_birthday: [
      {
        name:"Theme My Party Printed Birthday with cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" 999",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/1.png?alt=media&token=cdcd102c-d3ba-47fe-9886-c3662179a22d",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/1.png?alt=media&token=cdcd102c-d3ba-47fe-9886-c3662179a22d",
          tags:["Cake,Candal,Decorations"],
          Desciption: "PERFECT FOR GIRLS OR WOMEN BIRTHDAY PARTY: The foil curtain with happy birthday banner (made of thick paper - cardstock) and balloon birthday party decorations are an ideal addition to birthday party for women. Different from other party decorations, the gold foil curtain will light up your party and add a warm atmosphere, the birthday party will be an impressive event and leave an unforgettable memory."
      },
      {
        name:"PARTY MIDLINKERZ Happy Birthday with quality Cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" 1099",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1099.jpg?alt=media&token=3b7a37eb-472e-4cea-b7a6-f3adb8da7ecd",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1099.jpg?alt=media&token=3b7a37eb-472e-4cea-b7a6-f3adb8da7ecd",
          tags:["Cake,Candal,Decorations"],
          Description:- "SURPRISE YOUR LOVED ONES : This happy birthday decoration set can be use to surprise any of your loved ones on thier special occassions. You can combine with letters banner (made of thick paper - cardstock)s, kits, lights, or any purple rose gold, silver colours chrome balloons or pastel balloons."
      },
        {
        name:"Engineering Choice Birthday with Cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" 1099",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/birthday_img1.jpg?alt=media&token=5f4c8e01-df7a-42bd-ad65-071290ff6dcc",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/birthday_img1.jpg?alt=media&token=5f4c8e01-df7a-42bd-ad65-071290ff6dcc",
          tags:["Cake,Candal,Decorations..."],
          Description: "XTREMELY BEAUTIFUL AND ELEGANT SET UP: This birthday decoration kit combo when put up looks very beautiful and elegant. You can use this birthday decoration set for anyone like husband, wife, daughter, son, girlfriend, boyfriend, mother, father or as birthday decoration kit for girls of any age and any occasion like 1st birthday decoration for boys, 1st birthday decoration for girls, 30th, 50th, etc. They will all love your birthday surprise and you can make the celebration grand."
      },
      {
        name:"Father & Sons CHROME Birthday with Best cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" 1499",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/hp999.jpg?alt=media&token=6ddc20fd-ac31-44e1-bba3-214c5b8991db",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/hp999.jpg?alt=media&token=6ddc20fd-ac31-44e1-bba3-214c5b8991db",
          tags:["Cake,Decoration,Knife..."],
      Description: "Decorate with those elegant and good-looking party balloons by your imagination. These balloons would surely look beautiful down on the floor, up in the ceiling, or attached to the wall (your imagination). It's an ideal space fillers. This kit will also work great for memorable events of life. These party balloons will make your party full of enthusiasm and surprise! and for sure makes your party attractive, romantic, lovable and happy. Save your money and don't spend a fortune when you can get everything you need with our Economy Party kit Best cakes "
      },
      {
        name:"Balloonex Printed Doremon Theme Birthday Decoration cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1499",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/hp990.jpg?alt=media&token=81a94a9a-3e25-4170-b0d9-c286e7c443a8",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/hp990.jpg?alt=media&token=81a94a9a-3e25-4170-b0d9-c286e7c443a8",
          tags:["Best cakes, Decoration,Confetti balloon"],
          Description:"Decorate with in this pack- Happy Birthday foil Banner Blue (Set of 13 Letter) Economy Party kit with Best cakes"
      },
    {
        name:"Balloonex Printed Doremon Theme Birthday with Best cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1499",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/hp990.jpg?alt=media&token=81a94a9a-3e25-4170-b0d9-c286e7c443a8",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/hp990.jpg?alt=media&token=81a94a9a-3e25-4170-b0d9-c286e7c443a8",
          tags:["Decoration, Cake, Curtain..."],
          Description: "Decorate with in this pack- Happy Birthday foil Banner Blue (Set of 13 Letter) Economy Party kit with Best cakes"
      },
  {
        name:"Slid Theme Birthday Decoration with cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 899",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha899.jpg?alt=media&token=538e3aff-5d7c-4eca-aba2-78b21628e6d1",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha899.jpg?alt=media&token=538e3aff-5d7c-4eca-aba2-78b21628e6d1",
          tags:["Best Cake, Decoration, candal"],
          Desciption : "Best Cake with Party packs decoration for party is created with the mission to leave everyone with beautiful memories!Happy Birthday Golden Foil Letter Balloons"
      },
  {
        name:"Rozi Decoration Solid Birthday Combo Pack Happy Birthday",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 950",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha950.jpg?alt=media&token=286b60ea-4603-4eb4-b39f-a84cb50bb31c",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha950.jpg?alt=media&token=286b60ea-4603-4eb4-b39f-a84cb50bb31c",
          tags:["Cke, Decoration, Chrome ballooon"],
          Description: "Best Cake with Our brands believes to provide the good quality products to our customer so that customer will get satisfaction as they want. Our first priority is to provide the maximum satisfacion to our customers. We are making product with good quality raw material so that we can fulfill our customer needs."
      },
  {
        name:"Party Station Pack of Happy Birthday Decoration & cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1078",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1078.jpg?alt=media&token=6f2c3377-4caa-46d9-b8fb-0930b24cdbee",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1078.jpg?alt=media&token=6f2c3377-4caa-46d9-b8fb-0930b24cdbee",
          tags:["String LED, Best Cake,Peach Balloon"],
          Description: "Best Cake with Party packs decoration for home/office/Hotel best decoration will be decorated party is created with the mission to leave everyone with beautiful memories!Happy Birthday Golden Foil Letter Balloons"
      },
  {
        name:"Best decorative Party Birthday Decoration & Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1080",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haa1080.jpg?alt=media&token=637e6de3-d97e-47c0-84ed-d40f71d57225",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haa1080.jpg?alt=media&token=637e6de3-d97e-47c0-84ed-d40f71d57225",
          tags:["Cke, Golden Heart, Decoration"],
          Description: "Best Cake with wolrd best Party packs decoration for party is created with the mission to everyone with beautiful memories!Happy Birthday Golden Foil Letter Balloons with listed Items"
      },
  {
        name:"FANEX Solid Blue Happy Birthday Decoration & Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1150",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haa1150.jpg?alt=media&token=7d9ee4f2-2301-4025-9829-a06db553c05e",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haa1150.jpg?alt=media&token=7d9ee4f2-2301-4025-9829-a06db553c05e",
          tags:["Silver balloon,Cake,Golden Star"],
          Description: "Fanex party packs decoration for party is created with the mission to leave everyone with beautiful memories! We can only imagine how our high quality and amazing birthday decorations items can help you light up the beautiful night and make this unforgettable moment for your kids, yourself, your family and friends. Fanex is a professional brand that provides parties, weddings, birthday parties and decorative items. We hope that our efforts will provide you with the best products and services."
      },
  {
        name:"TTimmo4 Happy Birthday Decorations With Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1150",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/h1150.jpg?alt=media&token=6ff3748d-f822-49a9-af95-33c2e7dc2f7c",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/h1150.jpg?alt=media&token=6ff3748d-f822-49a9-af95-33c2e7dc2f7c",
          tags:["Cake,Rose Gold Confetti Balloon,decoration"],
          Description: "The CAkE with Blue Golden and White Birthday Decoration Theme set are very unique Concept Created by TTimmo4. This Balloons Decoration Theme set Can Be Used For any Birthday Party Theme Like Gangster Theme, Disco Theme, Birthday Parties For any Relation Birthday Decorative Like Mom, Dad, Son, Daughter, Sister, Brother, Husband, Wife, etc"
      },
  {
        name:"A-one Happy Birthday Decoration With Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 999",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/1000.jpg?alt=media&token=93e5cdc6-b9a3-40fb-8ee2-586e8b6bfe65",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/1000.jpg?alt=media&token=93e5cdc6-b9a3-40fb-8ee2-586e8b6bfe65",
          tags:["Pink pastel,peach balloon,Decoration"],
          Description: "The Cake with supplier solid Birthay celebration with parents/brother/sister/friends"
      },
  {
        name:"GoForStar choice birthday decoration With Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 890",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1079.jpg?alt=media&token=42ff2561-3eba-4a2f-895f-bc9963c8a663",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1079.jpg?alt=media&token=42ff2561-3eba-4a2f-895f-bc9963c8a663",
          tags:["Decoration,Heart,Gold confetti balloon"],
          Description: "The Cake with Best choice form the Goforstar party End It will be remember of year end for you and your family/friends"
      },
  {
        name:"Realistic Store Solid Happy Birthday Decoration With Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1099",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/h1099.jpg?alt=media&token=3223dc72-cc2f-47eb-a438-c8dc4b04c723",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/h1099.jpg?alt=media&token=3223dc72-cc2f-47eb-a438-c8dc4b04c723",
          tags:["Best CAKE, Realistic Store Solid Happy Birthday Decoration,Knife"],
          Description: "Best CAKE, Realistic Store Solid Happy Birthday Decoration Foil Curtain Metallic Confetti Balloons For Boys Girls Wife Adult Husband Mom Dad / Happy Birthday Decoration Item Balloon"
      },
  {
        name:"PARTY BREEZE Printed peppa pig birthday theme decoration",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 899",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haap1199.jpg?alt=media&token=da0e2694-493d-4823-b087-00fe0073543b",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haap1199.jpg?alt=media&token=da0e2694-493d-4823-b087-00fe0073543b",
          tags:["Best CAKE,Decoration,Ribben"],
          Description: "Best CAKE, Realistic Store Solid Happy Birthday Decoration Foil Curtain Metallic Confetti Balloons For Boys Girls Wife Adult Husband Mom Dad / Happy Birthday Decoration Item Balloon"
      },
  {
        name:"Friend BREEZE Printed peppa pig birthday & Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 birthday cap'],
          price:" Rs 1099",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haa1099.jpg?alt=media&token=4f80fde1-3d50-43c2-8bc2-267d1efcccf3",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/haa1099.jpg?alt=media&token=4f80fde1-3d50-43c2-8bc2-267d1efcccf3",
          tags:["Cake,White Balloon,Age Represent"],
          Description: "Best CAKE, for year end happines will be felling friends/ father/mother/sister/life parterner/Girls Wife Adult Husband Mom Dad / Happy Birthday Decoration Item Balloon"
      },
  
    ],
    premium_birthday: [
      {
        name:"SQE Solid Primium happy birthday & Cake",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 Birthday cap'],
          price:" 1299",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1299.jpg?alt=media&token=e02fb848-e9e3-453e-bd60-2c2a6c2db283",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/ha1299.jpg?alt=media&token=e02fb848-e9e3-453e-bd60-2c2a6c2db283",
          tags:["Special Cake,Candal,Rose Gold Heart"],
          Description: "Special Cake,Candal,Decorations,13 pcs Happy Birthday, ribben,candal,light,special cap,video/photo"
      },
      {
        name:"Alaina Solid Happy Birthday Decoration",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video graphics',
          '1 Birthday cap'],
          price:" 1569",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1565.jpg?alt=media&token=0afadce8-5b9a-4323-b814-4f3744abd8e3",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1565.jpg?alt=media&token=0afadce8-5b9a-4323-b814-4f3744abd8e3",
          tags:["Cake,Candal,Decorations"],
       Description: "COMBINE THIS KIT WITH OTHER 4TH BIRTHDAY DECORATIONS - This fourth birthday decoration combo can be combine with our other items like 4th happy birthday bunting, theme decorations, pink or white birthday decorations, birthday girl for 4 years, cup cake topper, cake topper, baby theme backdrop, 4th birthday photobooth props, b-day foil curtains, any kids theme decorations, latex balloons, glue dot, curling ribbon, girls headband, baby birthday decoration items 4 year etc."
      },
   {
        name:"Best Choice GoForStar Happy Birthday Decoration & Cake",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video graphics',
          '1 Birthday cap'],
          price:" 1575",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/paa1575.jpg?alt=media&token=d5fafe8f-44b4-4b9e-87b1-9691540d39c5",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/paa1575.jpg?alt=media&token=d5fafe8f-44b4-4b9e-87b1-9691540d39c5",
          tags:["Cake,Candal,Decorations"],
          Description: "CREATE A MEMORABLE PHOTO FOR LIFETIME- Set up a great wall backdrop at home, kids room, appartment anywhere with this black and gold birthday decoration item set for your baby girl and create a perfect selfie for her to remeber always. Combine this with girl birthday candle, sash, cake, gift, teddy bear, banner, cap, photo booth props, caps, hairband, year, candles, curtains, toppers, years, months, hair band, cards etc. Celebrate your loved ones birthday with our unique HIGH VALUE set."
      },
      {
        name:"GoForStar Premium Solid Happy Birthday Decoration & cake",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video graphics',
          '1 Birthday cap'],
          price:" 1515",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1515.jpg?alt=media&token=6b58e4d5-0c4a-443b-8c28-40dbc1050cbe",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1515.jpg?alt=media&token=6b58e4d5-0c4a-443b-8c28-40dbc1050cbe",
          tags:["Cake,Candal,Decorations"],
          Description: "SAFE TO USE AND NON TOXIC HAPPY BIRTHDAY DECORATION COMBO: Balloons are made of premium latex material which is durable, long-lasting. They can filled with both Helium or Air. The banner is also made up of high quality cardstock material. The birthday decoration combo items are also made of environmental PVC, they're all non-toxic and Eco-friendly."
      },
   {
        name:"Premium standard Happy Birthday Decoration",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video graphics',
          '1 Birthday cap'],
          price:" 1498",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1498.jpg?alt=media&token=83bed6cb-f101-4b83-85df-91b4677c2947",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1498.jpg?alt=media&token=83bed6cb-f101-4b83-85df-91b4677c2947",
          tags:["Best Choice Cake,Candal,Whole Decoration Items with Best wishes & Camera"],
          Description: "Best Choice Cake,Candal,Whole Decoration Items with Best wishes & Camera EASY TO SET UP AND LATEST BIRTHDAY DECORATION DESIGN: This birthday decoration items is so easy to use and decoration will be top notch with the The Happy Birthday Banner. The Pink metallic Balloons along with the pink Confetti Balloons and Pink foil Balloons create a complete set for your decorations. This kit also comes with curtain nets for birthday decoration which can be a great way to top it all off along with the lights included in the full birthday decoration kit."
      },
   {
        name:"GoForStar Premium Solid Happy Birthday Decoration",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video graphics',
          '1 Birthday cap'],
          price:" 1699",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1699.jpg?alt=media&token=8e2f0c7b-1d90-4f80-aef7-6bf7d7544bd2",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1699.jpg?alt=media&token=8e2f0c7b-1d90-4f80-aef7-6bf7d7544bd2",
          tags:["Cake,Candal,Decorations"],
          Description: "Best Choice Cake,Candal,Whole Decoration Items with Best wishes & Camera UNIQUE BIRTHDAY GIFT FOR HUSBAND OR KIDS - Surprise your partner, husband, son, kids with the perfect birthday decoration. These Birthday decoration items for boy and girl could be a great way to create the best party vibe. Just hang these decoration items for birthday party on a wall or in the background as a backdrop for birthday decoration and create some sweet everlasting memories for your loved ones!"
      },
   {
        name:"GoForStar Premium Happy Birthday Decoration",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 Birthday cap'],
          price:" 1499",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1499.jpg?alt=media&token=33f7f63b-9b32-4f65-99d6-b941c41a1df4",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1499.jpg?alt=media&token=33f7f63b-9b32-4f65-99d6-b941c41a1df4",
          tags:["Best Cake,Candal,Decorations"],
          Description: "Best Cake,Candal,Decorations,ribben,metalic ballon,helmate,big candal,knife,foil carten,heart,star,colorful joy with friens/parents"
      },
   {
        name:"Choice GoForStar Premium Happy Birthday Decoration",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs Happy Birthday',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 Birthday cap'],
          price:" 1550",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1550.jpg?alt=media&token=567fbfc2-5138-44c7-966c-b2b6b102e324",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1550.jpg?alt=media&token=567fbfc2-5138-44c7-966c-b2b6b102e324",
          tags:["Best Cake,Decorations,ribben"],
          Description: "Best Cake,Candal,Decorations,ribben,metalic ballon,helmate,big candal,knife,foil carten,heart,star,colorful joy with love/son/friens/parents"
      },
    ],
    luxurious_birthday: [
      {
        name:"FrillX Solid Rose gold Birthday decoration",
        items:[
      'Cake',
          'silver baloon',
          'golden matelic',
          'color balloon',
          'golden Heart',
          'golden Start',
      'net curtains',
      'string light',
          'Happy Birthday set',
          'age represents',
          'knife',
      'sash ribbon',
      'color ribbons',
          'candale',
          'confetti balloon',
          'star candale',
          'string light',
      'arch tape',
      'double side tape',
          'ribbons',
          'vapor light',
          'video graphics',
          '1 Birthday cap'],
          price:" 2499",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2499.webp?alt=media&token=3e46a850-ede3-4539-984c-daea2d8c903e",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2499.webp?alt=media&token=3e46a850-ede3-4539-984c-daea2d8c903e",
          tags:["Cake,candal,decoration"],
          Description: "1 Set of 13 Pcs Rosegold Happy Birthday Foil Balloon, 3 Pcs Rosegold Heart Shape Foil Balloons, 3 Pcs Silver Heart Shape Foil Balloons, 1 Pc Fairy Light, 50 Pcs Rosegold Metallic Balloons, 50 Pcs Silver Metallic Balloons, 3 Pcs Ribbon"
      },
      {
        name:"Hemito Pastel Balloon Birthday With Cake",
        items:[
      'Cake',
          'silver baloon',
          'golden matelic',
          'color balloon',
          'golden Heart',
          'golden Start',
      'net curtains',
      'string light',
          'Happy Birthday set',
          'age represents',
          'knife',
      'sash ribbon',
      'color ribbons',
          'candale',
          'confetti balloon',
          'star candale',
          'string light',
      'arch tape',
      'double side tape',
          'ribbons',
          'vapor light',
          'video graphics',
          '1 Birthday cap'],
          price:" 2799",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2799.webp?alt=media&token=87c1438e-ec22-4321-9f85-abda15cd92d0",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2799.webp?alt=media&token=87c1438e-ec22-4321-9f85-abda15cd92d0",
          tags:["CAke and CAMERA,decoration"],
      Description: "EXTREMELY BEAUTIFUL AND ELEGANT SET UP: This birthday decoration kit combo when put up looks very beautiful and elegant. You can use this birthday decoration set for anyone like husband, wife, daughter, son, girlfriend, boyfriend, mother, father or as birthday decoration kit for girls of any age and any occasion like 1st birthday decoration for boys, 1st birthday decoration for girls, 30th, 50th, etc. They will all love your birthday surprise and you can make the celebration grand."
      },
      {
        name:"Camera with CAKE And Shilpveda Solid Happy Birthday",
        items:[
      'Cake',
          'silver baloon',
          'golden matelic',
          'color balloon',
          'golden Heart',
          'golden Start',
      'net curtains',
      'string light',
          'Happy Birthday set',
          'age represents',
          'knife',
      'sash ribbon',
      'color ribbons',
          'candale',
          'confetti balloon',
          'star candale',
          'string light',
      'arch tape',
      'double side tape',
          'ribbons',
          'vapor light',
          'video camera',
          '1 Birthday cap'],
          price:" 2670",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2658.webp?alt=media&token=b0097faa-8212-4614-acf9-272ea6142317",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2658.webp?alt=media&token=b0097faa-8212-4614-acf9-272ea6142317",
          tags:["Best CAKE,decoration,cambo"],
      Description: "Best CAKE with Video Camera and Shilpveda Solid Happy Birthday Decoration Kit Combo (PACK OF 48) Balloon"
      },
  {
        name:"GOForStar Choice With Video Camera and Cake special",
        items:[
          'Cake',
          'silver baloon',
          'golden matelic',
          'color balloon',
          'golden Heart',
          'golden Start',
      'net curtains',
      'string light',
          'Happy Birthday set',
          'age represents',
          'knife',
      'sash ribbon',
      'color ribbons',
          'candale',
          'confetti balloon',
          'star candale',
          'string light',
      'arch tape',
      'double side tape',
          'ribbons',
          'vapor light',
          'video camera',
          '1 Birthday cap'],
          price:" 2799",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux2799.webp?alt=media&token=d51b8043-6328-4159-ad26-7adc4c2c9a27",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux2799.webp?alt=media&token=d51b8043-6328-4159-ad26-7adc4c2c9a27",
          tags:["Special Cake,decoration,Net Curtain"],
      Description: "Special You 1st birthday decoration with monthly Photo banner for boys, net fabric backdrop Diy Combo set with Balloon Garland arch tape, Fairy LED lights - 78 Pc Set / first(Blue)"
      },
    ],
    economy_anniversary: [
      {
        name:"Realistic Decoration For Home Kit with Cake",
        items:[
          'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 pcs anniversary cap'],
          price:" 899",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn1_899.webp?alt=media&token=d16b302a-8e66-45e8-bf25-beeddd559741",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn1_899.webp?alt=media&token=d16b302a-8e66-45e8-bf25-beeddd559741",
          tags:["Cake With PHoto,Decoration"],
      Description: "Cake With PHoto and 2 pcs Golden Curtains & 3 pcs Red Heart Foil with 30 pcs HD Metallic Balloons Decoration Kit Set Decorations Items"
      },
      {
        name:"DECOR GoForStar Solid Happy Anniversary With CAKE and camera",
        items:[
          'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs Happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 pcs anniversary cap'],
          price:" 1099",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEN2_1099.webp?alt=media&token=51f59e44-a1b0-45e0-945f-7a9c71a7df58",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEN2_1099.webp?alt=media&token=51f59e44-a1b0-45e0-945f-7a9c71a7df58",
          tags:["CaKE, Foil Curtain , Golden Star , Heart Shape"],
      Description: "Metallic Balloon & Curling Ribbon for Anniversary Party Decorations / Wedding Anniversary Decoration Items Balloon  (Black, Red, Gold, Silver, Pack of 70)"
      },
   {
        name:"Choice GoForStar With Cake and camera with best wishes",
        items:[
      'Cake',
          '15 pcs silver baloon',
          '15 pcs golden metallic',
          '2 pcs golden heart',
          '5 pcs stand red baloon',
          '5 pcs stand metallic baloon',
          '2 pcs Golden Start',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
          '5 pcs candal',
          '2 pcs chines light',
          '1 battery',
          '2 pcs vapor light',
          ' video graphics',
          '1 anniversary cap'],
          price:" 1150",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn3_1150.webp?alt=media&token=42896a1a-5549-43ee-8ea4-14589f819603",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn3_1150.webp?alt=media&token=42896a1a-5549-43ee-8ea4-14589f819603",
          tags:["Metalic Valloon,Rose Balloon,Cake"],
      Description: "16 letter of happy anniversary + 60 piece gold + red + blue balloon Balloon Balloon"
      },
   {
        name:"Happy Anniversary With Heart Shape & Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '2 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 pcs anniversary cap'],
          price:" 1099",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn4_1065.webp?alt=media&token=714a3891-5279-4eb7-9cc1-e884ed9c8b8c",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn4_1065.webp?alt=media&token=714a3891-5279-4eb7-9cc1-e884ed9c8b8c",
          tags:["Metallic Balloons,Chrome Balloon,Best Cake"],
      Description: "Metallic Balloons & Curling Ribbon for Wedding Anniversary Celebration , Room Decorating Items / Anniversary Decoration Items / Happy Anniversary Balloons for Party Decorations Balloon,Gold, Red"
      },
   {
        name:"Magic Balloons Solid Anniversary Decorations for Home Kit With Happy Anniversary Banner",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 pcs anniversary cap'],
          price:" 1089",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn5_1080.webp?alt=media&token=2edc4289-3c15-4d21-8d0d-efe25050bebb",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn5_1080.webp?alt=media&token=2edc4289-3c15-4d21-8d0d-efe25050bebb",
          tags:["Cake,Decoration,String LED Light"],
      Description: "Metallic Balloons 1set LED Lights Combo 54Pcs for 1st, 25th,50th Wedding Party Decoration Balloon  (Gold, Black, Yellow"
      },
   {
        name:"KHYALI Printed Happy Anniversary Decoration With Best Cakes",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 pcs anniversary cap'],
          price:" 999",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn6_999.webp?alt=media&token=99ccf397-da8c-41b8-a170-b0e18b9f432d",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn6_999.webp?alt=media&token=99ccf397-da8c-41b8-a170-b0e18b9f432d",
          tags:["Cake,Decoration,Peach Balloon"],
      Description: "It creates a mesmerising environment for the couple.This decor set can be a gift to bhaiya bhabi, brother, husband,wife, daughter in law, son in law, mom dad or any star couple"
      },
   {
        name:"Realistic Store Solid Happy Anniversary Foil",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 pcs anniversary cap'],
          price:" 1079",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn7_1075.webp?alt=media&token=3bd94ce2-5ed7-440b-8704-0994d2782c26",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn7_1075.webp?alt=media&token=3bd94ce2-5ed7-440b-8704-0994d2782c26",
          tags:["Brand CAKE,Decoration,Ribben"],
      Description: "Brand CAKE With Happy Anniversary Foil & Heart Foil With HD Metallic Balloons Decoration Kit & Curtains Balloon  (Pink, Silver, Pack of 55)"
      },
      {
        name:"GoForStar Store Solid Happy Anniversary & Cake",
        items:[
      'Cake',
          '10 pcs silver balloon',
          '10 pcs golden metallic',
      '10 pcs white metallic balloon',
          '2 pcs golden heart',
      '2 pcs curtains',
          '2 pcs golden Start',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '5 pcs color ribbon',
      '1 pcs sash ribbon',
      '2 pcs net curtains',
          '5 pcs candale',
          '2 pcs chines light',
      '1 pcs net curtains',
          '2 pcs vapor light',
          '4 pcs photo',
          '1 pcs anniversary cap'],
          price:" 899",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn8_899.webp?alt=media&token=ab20e8cb-40aa-4a0d-9454-3957a661e8a3",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn8_899.webp?alt=media&token=ab20e8cb-40aa-4a0d-9454-3957a661e8a3",
          tags:["CAke,Decoration,Burgundy Balloon"],
      Description: "CAke With Happy Anniversary Foil With 50 HD Metallic Balloons with Foil Curtain for Party Decorations Kit Letter Balloon"
      },
    ],
    premium_anniversary: [
      {
        name:"Balaji Solid Red I Love You Decoration Happy Anniversary",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 anniversary cap'],
          price:" 1399",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn1_1399.webp?alt=media&token=35016225-c0d1-494a-9705-479083f5d462",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn1_1399.webp?alt=media&token=35016225-c0d1-494a-9705-479083f5d462",
          tags:["Stylish Anniversar,Cake,Rose Gold"],
      Description: "Stylish Anniversary Decoration Combo - with Bunting Happy Anniversary Gold (16 Letter), I Love You Banner, 30 red,gold balloons, 2 heart foil, 1 confetti…Complete decoration set for For Your Love , Friendship Wedding,Birthday,Anniversary. Use These Balloon As An Accent To Any Party Theme Or Color Scheme.It Would Really Make The Party Outstanding,Attractive,And More Fun To Everybody,You Can Make Any Design Or Style Of Decoration With This Balloon."
      },
      {
        name:"GoForStar Party Station Solid PACK & Cake",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 pcs anniversary cap'],
          price:" 1699",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn2_1699.webp?alt=media&token=2bc58f65-8aaf-49b1-8681-dcfe6d8667b5",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn2_1699.webp?alt=media&token=2bc58f65-8aaf-49b1-8681-dcfe6d8667b5",
          tags:["Best Cakes,Decoration,Arch Tape"],
      Description: "Best Cakes With 1 SET OF 16PCS HAPPY ANNIVERSARY GOLDEN FOIL BALLOON, 1 PC OF 9 MTR FAIRY LIGHT, 35 PCS OF 9 RED HEART BALLOONS, 35 PCS OF 9 RED BALLOONS, 1 PC OF AIR PUMP, 1 PC OF RED RIBBON, 12 PCS OF T-LIGHT CANDLES"
      },
      {
        name:"Magic Balloons Solid Rose Gold Anniversary Decoration Kit:",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 anniversary cap'],
          price:" 1399",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn3_1399.webp?alt=media&token=9c3c6817-ef13-4d81-a086-0fddca9f8677",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn3_1399.webp?alt=media&token=9c3c6817-ef13-4d81-a086-0fddca9f8677",
          tags:["Cake,decoration,Gold Confetti Balloon"],
      Description: "Brand Cake With Rose Gold Foil Happy Anniversary & Cursive Love, Silver Heart Foil Balloon & Curtain, Silver & Rose Gold Balloon, Balloon Pump 82Pc DIY Kit Balloon"
      },
  {
        name:"Wish & Willow Solid Rose Gold Happy Anniversary Decoration",
        items:[
      'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video graphics',
          '1 anniversary cap'],
          price:" 1450",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn4_1450.webp?alt=media&token=0e7270f2-df57-45d4-84bb-9e5d46ea88b5",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn4_1450.webp?alt=media&token=0e7270f2-df57-45d4-84bb-9e5d46ea88b5",
          tags:["Best cake with Camera,Golden Heart,Cap"],
      Description: "Best cake with Camera and Wish & Willow Solid Rose Gold Happy Anniversary Decoration"
      },
  {
        name:"Goforstar Realistic Store Happy Anniversary Decoration",
        items:[
          'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age representation',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '1 pcs vapor light',
          'video graphics',
          '1 anniversary cap'],
          price:" 1450",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn5_1450.webp?alt=media&token=24d44a97-4fd7-46e1-ba2e-cecbaa43b12a",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn5_1450.webp?alt=media&token=24d44a97-4fd7-46e1-ba2e-cecbaa43b12a",
          tags:["Cake,String LED Light,Pink pastel balloon"],
      Description: "Happy anniversary decoration kit - (41 Pcs) 1 Pcs Happy anniversary pink banner + 4 Pcs Pre filled confetti balloons (Golden) + 36 Pcs Metallic Balloons ( purple, pink & light blue) with ribbon included"
      },
  {
        name:"PARTY MIDLINKERZ Solid Happy Anniversary Balloons Decoration Kit",
        items:[
          'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 anniversary cap'],
          price:" 1509",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn6_1505.webp?alt=media&token=ff3d4d12-89ac-4959-a06a-1f3fa47c1634",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn6_1505.webp?alt=media&token=ff3d4d12-89ac-4959-a06a-1f3fa47c1634",
          tags:["Gold Confetti Balloon,pink chrome,Cake"],
      Description: "Decoration Kit 59 Pcs, 1 set of Golden 13Pcs HappyAnniversary alphabet foil balloons an 50pcs HD Metallic balloons(Gold, Black & Silver) and 5Pcs Confetti Balloon with with 1Pc Glue Dot & 1Pc Arch Strip and1Pc Ribbon for Husband Kids Girl Boys Balloons Decorations Items Combo with Helium Letters Foil Balloon Banner, Latex Metallic Balloons Balloon"
      },
  {
        name:"GoForStar Premium With best Cake with camera",
        items:[
         'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video shoot',
          '1 anniversary cap'],
          price:" 1599",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn8_1599.webp?alt=media&token=310b1092-ad71-404d-8f63-433da3602a85",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn8_1599.webp?alt=media&token=310b1092-ad71-404d-8f63-433da3602a85",
          tags:["Cake,Candal,Decorations"],
      Description: "UNIQUE Anniversary GIFT FOR HUSBAND OR KIDS - Surprise your partner, husband, son, kids with the perfect birthday decoration. These Birthday decoration items for boy and girl could be a great way to create the best party vibe. Just hang these decoration items for birthday party on a wall or in the background as a backdrop for birthday decoration and create some sweet everlasting memories for your loved ones!"
      },
  {
        name:"Best cAke with FLIPZONE Solid Happy Anniversary Decoration",
        items:[
          'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video shoot',
          '1 anniversary cap'],
          price:" 1199",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn7_1199.webp?alt=media&token=6efbedcc-dee8-40ee-b788-425b0c8b4b3a",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn7_1199.webp?alt=media&token=6efbedcc-dee8-40ee-b788-425b0c8b4b3a",
          tags:["Cake,Peach Balloon,Rose Gold Confetti Balloon"],
      Description: "Happy Anniversary Decoration Items with Banner, Balloons, Foil Balloon 50Pcs Set for 1st, 5Th,25th Party Room Decoration Combo Set/Couple Wedding, Marriage Celebration"
      },
  {
        name:"Dinipropz Happy Anniversary Combo Kit With Cake",
        items:[
          'Cake',
          '18 pcs silver baloon',
          '18 pcs golden matelic',
          '15 pcs color baloon',
          '2 pcs Golden Heart',
          '2 pcs Golden Start',
      '2 pcs net curtains',
      '1 pcs string light',
          '1 set 13 pcs happy anniversary',
          '1 pcs age represents',
          '1 pcs knife',
      '1 pcs sash ribbon',
      '5 pcs color ribbons',
          '5 pcs candale',
          '2 confetti balloon',
          '2 pcs star candale',
          '2 pcs light',
          '1 battery',
      '1 pcs arch tape',
      '1 pcs double side tape',
          '5 pcs Ribben',
          '2 pcs vapor light',
          'video camera',
          '1 anniversary cap'],
          price:" 1405",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn9_1405.webp?alt=media&token=15b3bbc7-c688-4bb8-ab82-dc51753b0e26",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/Apn9_1405.webp?alt=media&token=15b3bbc7-c688-4bb8-ab82-dc51753b0e26",
          tags:["Cake,Candal,Decorations"],
      Description: "EASY TO SET UP AND LATEST anniversary DECORATION DESIGN: This anniversary decoration items is so easy to use and decoration will be top notch with the The Happy Birthday Banner. The Pink metallic Balloons along with the pink Confetti Balloons and Pink foil Balloons create a complete set for your decorations. This kit also comes with curtain nets for birthday decoration which can be a great way to top it all off along with the lights included in the full birthday decoration kit."
      },
    ],
    luxurious_anniversary: [
      {
        name:"Special You Backdrop happy anniversary decoration",
        items:[
      'Luxurious Cake',
      'String LED Light',
      '2 Net Curtains',
      'Peach Balloon',
      'White Balloon',
      'Metallic Balloon',
      'Burgundy Balloon',
      'Gold Confetti',
      'Gold Anniversary',
      'Arch Tape',
      'Double side Tape',
      'Big Candal',
          'silver baloon',
          'golden matelic',
          'Golden Heart',
          'Golden Start',
          '1 set 13 pcs Happy anniversary ',
          'Ege represents',
          'knife',
          'candal',
          'chines light',
          'Battery',
          'vapor light',
          'Video camera',
          'anniversary cap'],
          price:" 2900",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux_2900.jpg?alt=media&token=dd81398f-5c50-4392-95cc-789938e2a842",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux_2900.jpg?alt=media&token=dd81398f-5c50-4392-95cc-789938e2a842",
          tags:["Cake,Candal,Decorations"],
      Description: "[PREMIUM QUALITY] Our 25th happy anniversary decoration items kit is made with the best quality materials. Happy Anniversary party Balloons for decoration are made out of natural latex and tested rigidly before packing. The happy anniversary party banner is vibrant in colour and can be used multiple times.The backdrop cloth for decoration is made with good quality fabric and can be reused multiple times"
      },
      {
        name:"Rose Gold Love Theme Rubber Balloons For Anniversary",
        items:[
          'Camera',
      'Luxurious Cake',
      'String LED Light',
      'Pink Pastel Balloon',
      'Pink Chrome Balloon',
      'Rose Gold',
      'Rose Gold Confetti Balloon',
      '2 Net Curtains',
      'Peach Balloon',
      'White Balloon',
      'Metallic Balloon',
      'Burgundy Balloon',
      'Gold Confetti',
      'Gold Anniversary',
      'Arch Tape',
      'Double side Tape',
      'Big Candal',
          'silver baloon',
          'golden matelic',
          'Golden Heart',
          'Golden Start',
          '1 set 13 pcs Happy anniversary',
          'Ege represents',
          'knife',
          'candal',
          'chines light',
          'Battery',
          'vapor light',
          'camera',
          'anniversary cap'],
          price:" 2850",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux_2850.jpg?alt=media&token=8350c88c-737b-4cef-a008-8aff215e34e9",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux_2850.jpg?alt=media&token=8350c88c-737b-4cef-a008-8aff215e34e9",
          tags:["Cake,Candal,Decorations"],
      Description: "This 61 Pcs DIY Kit includes Pink Pastel Balloons (40Pcs), Pink Chrome Balloon (10Pcs), Rose Golden Confetti Balloon (4Pcs), Rose Golden Heart Foil Balloon 18inch (4Pcs), Rose Golden Cursive Love Foil Balloon (1Pc), Balloon Pump (1Pc), Glue Dot"
                                                                                                                                    
      },
      {
        name:"GoForSatr Special Choice anniversary decoration",
        items:[
      'Luxurious Cake',
      'String LED Light',
      '2 Net Curtains',
      'Peach Balloon',
      'White Balloon',
      'Metallic Balloon',
      'Burgundy Balloon',
      'Gold Confetti',
      'Gold Anniversary',
      'Arch Tape',
      'Double side Tape',
      'Big Candal',
          'Silver baloon',
          'Golden matelic',
          'Golden Heart',
          'Golden Start',
          '1 set 13 pcs Happy anniversary',
          'Age represents',
          'knife',
          'Candal',
          'Chines light',
          'Battery',
          'Vapor light',
          'Video Camera',
          'Anniversary cap'],
          price:" 2900",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux3350.jpg?alt=media&token=6cf69295-8e3f-4f8d-a49f-a36157edc92e",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux3350.jpg?alt=media&token=6cf69295-8e3f-4f8d-a49f-a36157edc92e",
          tags:["Cake,Candal,Decorations"],
      Description: "[SUITABLE FOR MULTIPLE OCCASIONS] This happy 25th anniversary decoration items kit includes our best seller balloons for decoration combo. This happy anniversary decoration kit can be used for multiple occasions like wedding anniversary ,1st anniversary decoration items kit , 25th anniversary decoration items kit , birthday decoration items kit , Wedding proposal decoration items kit , Pre-wedding decoration items kit and so much more",
      },
    ],
    avaiableOption:{
      birthday:[
        {
          type:'Birthday',
          name:"Economy",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/birthday_img1.jpg?alt=media&token=5f4c8e01-df7a-42bd-ad65-071290ff6dcc",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/birthday_img1.jpg?alt=media&token=5f4c8e01-df7a-42bd-ad65-071290ff6dcc",
          tags:["Cake,Candal,Decorations"]
        },
        {
          type:'Birthday',
          name:"Premium",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1498.jpg?alt=media&token=83bed6cb-f101-4b83-85df-91b4677c2947",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pa1498.jpg?alt=media&token=83bed6cb-f101-4b83-85df-91b4677c2947",
          tags:["Cake,Candal,Decorations"]
        },
        {
          type:'Birthday',
          name:"Luxurious",
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2499.webp?alt=media&token=3e46a850-ede3-4539-984c-daea2d8c903e",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lu2499.webp?alt=media&token=3e46a850-ede3-4539-984c-daea2d8c903e",
          tags:["Cake,Candal,Decorations"]
        },
      ],
      anniversary:[
        {
          name:"Economy",
          type:'anniversary',
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn3_1150.webp?alt=media&token=42896a1a-5549-43ee-8ea4-14589f819603",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/AEn3_1150.webp?alt=media&token=42896a1a-5549-43ee-8ea4-14589f819603",
          tags:["Cake,Candal,Decorations"]
        },
        {
          name:"Premium",
          type:'anniversary',
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn4_1450.webp?alt=media&token=0e7270f2-df57-45d4-84bb-9e5d46ea88b5",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/APn4_1450.webp?alt=media&token=0e7270f2-df57-45d4-84bb-9e5d46ea88b5",
          tags:["Cake,Candal,Decorations"]
        },
        {
          name:"Luxurious",
          type:'anniversary',
          logo_url:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux3350.jpg?alt=media&token=6cf69295-8e3f-4f8d-a49f-a36157edc92e",
          full_image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/lux3350.jpg?alt=media&token=6cf69295-8e3f-4f8d-a49f-a36157edc92e",
          tags:["Cake,Candal,Decorations"]
        }
      ]
    },
       cakesAvailable:[
      {
        label:"special delicious vanilla Cake",
        value:"special delicious vanilla Cake",
        image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/special-delicious-vanilla-cake-599.webp?alt=media&token=c34533a6-c29c-4574-9402-f4de56cc6fea",
      },
      {
        label:"heavenly chocolate overload cake",
        value:"heavenly chocolate overload cake",
        image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/heavenly-chocolate-overload-cake-599.webp?alt=media&token=00b01078-208c-4082-a636-4d42e145a171",
     
      },
      {
        label:"vanilla-buttercream-cake",
        value:"vanilla-buttercream-cake",
        image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/vanilla-buttercream-cake_699.webp?alt=media&token=65c4d7db-3319-4086-81ff-df7fa2da7ef5",
      },
      {
        label:"creamy drip chocolate cake",
        value:"creamy drip chocolate cake",
        image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/creamy-drip-chocolate-cake.webp?alt=media&token=4ea418eb-d8fa-4afc-870d-bbc4d136f7af",
      },
      {
        label:"pineapple cake",
        value:"pineapple cake",
        image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/pineapple-cake.webp?alt=media&token=b470186f-fa51-4bfc-86d9-c3df65aba938",
        price:"560"
      },
      {
        label:"black forest cake",
        value:"black forest cake",
        image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/black-forest-cake-half-kg_1.webp?alt=media&token=dd6a12d2-9e12-46ab-9ac8-b8cadf084920",
      },
    ],
    selected_cake:{
      label:"Pound Cake",
      value:"Pound Cake",
      image:"https://firebasestorage.googleapis.com/v0/b/goforstar-4e890.appspot.com/o/black-forest-cake-half-kg_1.webp?alt=media&token=dd6a12d2-9e12-46ab-9ac8-b8cadf084920",
    }
  };
// Define a reducer function that updates the state based on actions
function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SELECT_CAKE':
      return {...state,selected_cake:action.payload}
    default:
      return state;
  }
}

// Create the Redux store with the reducer and initial state
const store = createStore(reducer);

export default store;